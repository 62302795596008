import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/SEO'
import Error from '../components/ErrorPage'
import i18n from '../../config/i18n'

const ErrorPage = ({ pageContext: { locale }, location }) => (
  <>
    <SEO pathname={location.pathname} locale={locale} title={i18n[locale].seo.error.title} />
    <Error />
  </>
)

ErrorPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
export default ErrorPage

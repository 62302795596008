import React from 'react'
import { Link } from 'gatsby'

import { LocaleContext } from '../Layout'
import background from '../../../content/images/half-size-cat.png'
import bubbles from '../../../content/images/bubbles.svg'
import * as S from './styled'

const Error = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'
  return (
    <S.Wrapper>
      <S.Image src={background} />
      <S.TopWrapper>
        <S.Bubbles src={bubbles} alt="Error" />
      </S.TopWrapper>
      <S.InnerWrapper>
        <S.Title>{i18n.errorPage.title}</S.Title>
        <S.Text>{i18n.errorPage.description}</S.Text>
        <Link to={isPt ? '/' : '/en'}>
          <S.Button>{i18n.errorPage.button}</S.Button>
        </Link>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

export default Error
